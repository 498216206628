import React from "react";
import { InfoCard, Scene } from "../../layout/shopify";

const Installed = () => (
  <Scene>
    <InfoCard title="Latitude Shopify Plugin" description="v 0.0.1"></InfoCard>
  </Scene>
);

export default Installed;
